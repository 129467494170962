import { AvailabilityEnum, QuantityMeasureEnum } from "models/Product";

export const AVAILABILITY_ENUM_ALL_CASES: AvailabilityEnum[] = ['AVAILABLE', 'NOT_AVAILABLE', 'REMOVED'];

export const availabilityColor = (status: AvailabilityEnum) => {
  switch (status) {
    case 'AVAILABLE': return 'var(--devo-green)';
    case 'NOT_AVAILABLE': return 'var(--devo-red)';
    case 'REMOVED': return 'var(--gray)';
  }
};

export const availabilityDescription = (status: AvailabilityEnum) => {
  switch (status) {
    case 'AVAILABLE': return 'Available';
    case 'NOT_AVAILABLE': return 'Unavailable';
    case 'REMOVED': return 'Archived';
  }
};

export const availabilityToBootstrapVariant = (status: AvailabilityEnum) => {
  switch (status) {
    case 'AVAILABLE': return 'success';
    case 'NOT_AVAILABLE': return 'warning';
    case 'REMOVED': return 'dark';
  }
};

// --------------------------------------------
// Size
// --------------------------------------------

export const QUANTITY_MEASURE_ENUM_ALL_CASES: QuantityMeasureEnum[] = ['MILLIGRAMS', 'GRAMS', 'KILOGRAMS', 'CENTILITRES', 'MILLILITRES', 'LITRES'];
export const quantityMeasureDescription = (measure: QuantityMeasureEnum) => {
  switch (measure) {
    case 'MILLIGRAMS': return "mg";
    case 'GRAMS': return "g";
    case 'KILOGRAMS': return "kg";
    case 'CENTILITRES': return "cl";
    case 'MILLILITRES': return "ml";
    case 'LITRES': return "l";
  }
};

export const formatSizeInput = (input: string, fallback: any = 0) => {
  const formatted = input.replace(/[^\d.]/, "");
  const num = parseFloat(formatted) || fallback;
  if (!num) return num;
  return Math.round(num);
};

// --------------------------------------------
// Price
// --------------------------------------------

export const roundTo = (num: number, dp: number = 0) => Math.round((num + Number.EPSILON) * Math.pow(10, dp)) / Math.pow(10, dp);

export const formatPriceSeparateWhole = (price: number) => {
  const pounds = Math.trunc(price);
  return pounds.toLocaleString('en-US', { maximumFractionDigits: 0 });
};

export const formatPriceSeparateFractional = (price: number) => {
  const roundedToPrice = roundTo(price, 2);
  const pounds = Math.trunc(price);
  const pennies = Math.trunc(roundTo((roundedToPrice - pounds)*100, 2));
  return pennies < 10 ? `0${pennies}` : pennies;
};

export const formatPrice = (price: number, fallback: any = "£0.00") => {
  if (!price) return fallback;
  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  return formatter.format(price);
};

export const formatPriceNumber = (price: number, fallback: any = "0", removeDecimal: boolean = false): string => {
  if (!price) return fallback;

  if (removeDecimal && price % 1 === 0) {
    return price.toString();
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
  });
  
  return formatter.format(price);
};

export const formatPriceInput = (input: string, fallback: any = 0): number => {
  const formatted = input.replace(/[^\d.]/, "");
  const num = parseFloat(formatted) || fallback;
  if (!num) return num;
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const formatLivePriceInput = (input: string): number => {
  const formatted = input.replace(/[\D]/, "").replaceAll('.', '').replaceAll(',', '');
  const num = parseFloat(formatted);
  if (!num) return null;
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const shopToDevoPrice = (price: number, markup: number): number => {
  const newPrice = price * (1 + markup);
  const scaled = Math.ceil((newPrice + Number.EPSILON) * 100) / 100;
  return roundUp(scaled);
};

export const devoToShopPrice = (price: number, markup: number): number => {
  const scaled = price / (1 + markup);
  const stripped = Math.trunc(scaled * 100) / 100;
  return stripped;
};

export const isPriceChangeAcceptable = (original: number, newPrice: number): boolean => {
  if (!original || !newPrice) return true;
  return newPrice >= 0.5*original && newPrice <= 2*original;
};

const roundUp = (price: number): number => {
  const lastDigit = Math.round((price % 0.1) * 100);
  const increaseBy = ((10 - lastDigit) % 5) / 100;
  return price + increaseBy;
};