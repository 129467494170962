import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react';

export type LOG_LEVEL = 'fatal' | 'error' | 'warning' | 'info' | 'debug' | 'critical';
export type LOG_CATEGORY =
  'home' | 'shops' | 'products' | 'invoices' | 'settings' | 'orders' |
  'recommended' | 'version-history' | 'undo' |
  'session' | 'auth' | 'signin' |
  'app' | 'api' | 'analytics' |
  'debugging' | 'general' | 'todo' |
  'sentry' | 'mixpanel' | 'mixpanel-local' | 'intercom';

type LogProps = {
  level?: LOG_LEVEL;
  category?: LOG_CATEGORY;
  message: string;
  data?: any;
};

export const log = (props: LogProps) => {
  const {
    level = 'debug',
    category,
    message,
    data,
  } = props;

  if (process.env.NEXT_PUBLIC_DEVO_ENV !== 'production') {
    switch (level) {
      case 'info':
        console.info(description(props));
        break;
      case 'debug':
        console.debug(description(props));
        break;
      case 'warning':
        console.warn(description(props));
        break;
      default:
        console.log(description(props));
        break;
    }
  }

  if (process.env.NEXT_PUBLIC_DEVO_ENV) {
    const severity = levelToSeverity(level);

    switch (severity) {
      case 'critical':
      case 'fatal':
      case 'error':
      case 'warning':
        Sentry.captureException(new Error(message), {
          level: severity,
          extra: data,
          tags: { category }
        });
        break;

      case 'log':
      case 'info':
      case 'debug':
        Sentry.addBreadcrumb({
          level: severity,
          category,
          message,
          data
        });
        break;

      default:
        break;
    }
  }
};

// CONVENIENCE

const fatal = (message: string, category: LOG_CATEGORY = 'general', data: any = null) => {
  log({ level: 'fatal', message, category, data });
};

const error = (message: string, category: LOG_CATEGORY = 'general', data: any = null) => {
  log({ level: 'error', message, category, data });
};

const warning = (message: string, category: LOG_CATEGORY = 'general', data: any = null) => {
  log({ level: 'warning', message, category, data });
};

const info = (message: string, category: LOG_CATEGORY = 'general', data: any = null) => {
  log({ level: 'info', message, category, data });
};

const debug = (message: string, category: LOG_CATEGORY = 'general', data: any = null) => {
  log({ level: 'debug', message, category, data });
};

const critical = (message: string, category: LOG_CATEGORY = 'general', data: any = null) => {
  log({ level: 'critical', message, category, data });
};

const Log = { fatal, error, warning, info, debug, critical };
export default Log;

// HELPERS

const description = (props: LogProps) => {
  const { level = 'debug', category, message, data } = props;
  const emoji = levelEmoji(level);
  var output = `${emoji} ${level.toUpperCase()}`;
  if (category) output = `${output} [${category}]`;
  output = `${output}\n${message}`;
  try {
    if (data) output = `${output}\n\n${JSON.stringify(data, null, 2)}`;
  } catch (e) {
    console.log('ERROR stringifying JSON for error description', 'debugging', data)
  }
  return output;
};

const levelEmoji = (level: LOG_LEVEL): string => {
  switch (level) {
    case 'critical':
    case 'fatal':
      return '❌';
    case 'error':
      return '🚫';
    case 'debug':
      return '🛠';
    case 'info':
      return 'ℹ️';
    case 'warning':
      return '🟡';
  }
}

const levelToSeverity = (level: string): Sentry.Severity => {
  switch (level) {
    case 'fatal':
      return Severity.Fatal;
    case 'error':
      return Severity.Error;
    case 'warning':
      return Severity.Warning;
    case 'log':
      return Severity.Log;
    case 'info':
      return Severity.Info;
    case 'debug':
      return Severity.Debug;
    case 'critical':
      return Severity.Critical;
    default:
      return Severity.Debug;
  }
}