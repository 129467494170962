import { getCookieFromBrowser, setCookie, removeCookie } from 'helper/session';
import { UserModel, AuthTokenModel } from 'models/User';
import Log from './monitoring';
import * as Sentry from '@sentry/react';
import Mixpanel, { identifyToMixpanel, setMixpanelSuperProperty, setMixpanelUserProperty } from './Mixpanel';

const authTokenKey = 'dauthtoken';
const refreshTokenKey = 'drefreshtoken';

export const setSessionTokens = (tokens: AuthTokenModel) => {
  setAuthToken(tokens.authToken)
  setCookie(refreshTokenKey, tokens.refreshToken);

  const mixpanel = window['mixpanel'];
  const user = getCurrentUser();
  if (!mixpanel) return Log.warning('Mixpanel client not found when setting login tokens', 'mixpanel', null);
  
  Log.info('Identify and set super properties', 'mixpanel', { uuid: user?.uuid });
  if (user) {
    setMixpanelSuperProperty('$first_name', user.firstName);
    setMixpanelSuperProperty('$last_name', user.lastName);
    setMixpanelSuperProperty('$email', user.email);
    setMixpanelSuperProperty('$phone', user.phoneNumber);
    identifyToMixpanel(user.uuid);
  }
}

export const getAuthToken = () => {
  const authToken = getCookieFromBrowser(authTokenKey);
  if (!authToken || authToken.length < 1) { return null };
  return authToken;
}

export const setAuthToken = (authToken: string) => {
  setCookie(authTokenKey, authToken);
  updateSentryUser(getCurrentUser());
}

export const getRefreshToken = () => {
  const refreshToken = getCookieFromBrowser(refreshTokenKey);
  if (!refreshToken || refreshToken.length < 1) { return null };
  return refreshToken;
}

export const getCurrentUserUuid = (enableLogout?: boolean) => {
  if (getRefreshExpiryTime() < 0) {
    if (enableLogout === true) {
      Mixpanel.track('logout', { action: 'expired_token' });
      logout();
    }
    return null;
  }

  const user = getAuthData();
  if (user) {
    return user['user_uuid'];
  } else {
    return null;
  }
}

export const getCurrentUser = (disableLogout?: boolean) => {
  if (getRefreshExpiryTime() < 0) {
    if (disableLogout === true) {
      Mixpanel.track('logout', { action: 'expired_token' });
      logout();
    }
    return null;
  }

  const data = getAuthData();
  if (data) {
    return JSON.parse(data.user);
  } else {
    return null;
  }
}

export const logout = (callback: any = null) => {
  setMixpanelUserProperty({
    current_basket_items: '',
    current_basket_value: '',
    current_promotion: '',
    current_shop: '',
  }, false);
  identifyToMixpanel();

  setTimeout(() => {
    removeCookie(authTokenKey);
    removeCookie(refreshTokenKey);
    updateSentryUser(null);
    window['mixpanel']?.reset();
    if (callback) callback();
  }, 500);
}

export const getAuthExpiryTime = () => getExpiryTime(authTokenKey);
export const getRefreshExpiryTime = () => getExpiryTime(refreshTokenKey);

// HELPERS

export const separateName = (fullName: string) => {
  const components = fullName.split(' ').filter((val) => val.length > 0);
  if (components.length == 0) {
    return ['', ''];
  } else if (components.length > 1) {
    return [components.shift(), components.join(' ')];
  } else {
    const a = [components.shift(), ''];
    return a;
  }
};

export const formatNames = (firstName: string, lastName: string): string => {
  if (firstName && lastName) {
    if (firstName.length > 0 && lastName.length > 0) {
      return firstName + ' ' + lastName;
    } else {
      return firstName + lastName;
    }
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  } else {
    return '';
  }
}

export const formattedAddress = (a): string => {
  var str = '';
  if (a.addressLine1) str = `${str}${a.addressLine1}, `;
  if (a.addressLine2) str = `${str}${a.addressLine2}, `;
  if (a.city) str = `${str}${a.city}, `;
  if (a.postcode) str = `${str}${a.postcode}, `;

  if (str.substring(str.length-2, str.length) == ', ') {
    return str.slice(0, -2);
  }
  return str;
}

export const validateEmail = (email?: string): boolean => {
  if (!email) {
    return false;
  }

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

//------------------------------------------------------------------------------
// PRIVATE UTILITIES

const updateSentryUser = (user: UserModel) => {
  if (!user) {
    Log.debug('Clearing Sentry user', 'auth', null);
    Sentry.setUser(null);
    return;
  }

  const sentry = {
    id: user.uuid,
    username: `${user.firstName} ${user.lastName}`,
    email: user.email,
  };
  Log.debug('Updating Sentry user', 'auth', sentry);
  Sentry.setUser(sentry);
};

const getExpiryTime = (key: string) => {
  const data = getData(key);
  if (!data?.exp) return null;
  const now = new Date().getTime() / 1000;
  return data.exp - now;
}

const getAuthData = () => getData(authTokenKey);
const getRefreshData = () => getData(refreshTokenKey);
const getData = (key: string) => {
  const data = getCookieFromBrowser(key);
  if (!data || data.length < 1) return null;
  return parseJwt(data);
}

const parseJwt = (token: string) => {
  if (!token) return null;
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const parseUserDataFromJwt = (token: string) => {
  const data = parseJwt(token);
  if (data) {
    return JSON.parse(data.user);
  } else {
    return null;
  }
};
