
export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/';
export const LIVE_PRODUCTS_ROUTE = '/products/live';
export const ADD_PRODUCTS_ROUTE = '/products/add';
export const SCAN_ROUTE = '/scan';
export const LIVE_ORDERS_ROUTE = '/orders/live';
export const PAST_ORDERS_ROUTE = '/orders/past';
export const INVOICES_ROUTE = '/invoices';
export const INVOICE_PDF_ROUTE = '/invoice/pdf/[year]/[month]';
export const SETTINGS_ROUTE = '/settings';